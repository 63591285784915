<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <h1 class="mb-5">Lista rilevazioni</h1>
      <div style="font-size: 1.5rem">
        <b-badge variant="warning">
          <b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
        </b-badge>
      </div>
    </div>
    <b-card class="mb-4">
      <b-row>
        <b-col cols="12">
          <div
            class="d-100 d-flex justify-content-between align-items-center mb-4"
          >
            <div>
              <b-button @click="$router.go(-1)" size="sm"
                ><b-icon icon="chevron-left" aria-hidden="true"></b-icon> Torna
                indietro</b-button
              >
            </div>
            <div class="d-flex justify-content-end">
              <!-- <b-button
                size="sm"
                class="mb-2 mr-2"
                v-b-toggle.collapse-advancedFilter
                :disabled="advancedFilter_visible"
              >
           
                <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced
                Filter
              </b-button> -->
              <b-button
                :disabled="loading"
                @click="generaDocumentoDiDossier"
                size="sm"
                class="mb-2 mr-2"
              >
                <b-icon icon="plus" aria-hidden="true"></b-icon>
                {{
                  loading
                    ? "Generando il documento... "
                    : "Genera documento di Dossier"
                }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col size="12"
          ><div>
            <b-form-checkbox v-model="toggleVista" name="check-button" switch>
              Espandi
            </b-form-checkbox>
          </div></b-col
        >
      </b-row>
    </b-card>
    <b-collapse
      style="overflow: visible"
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card style="overflow: visible" class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <label>Ordine di servizio</label>
            <b-form-select
              v-model="advancedFilter.ordine"
              :options="ordineOptions"
            ></b-form-select>
          </b-col>
          <b-col>
            <label>CIE</label>
            <b-form-select
              v-model="advancedFilter.CIE"
              :options="CIEOptions"
            ></b-form-select>
          </b-col>
          <b-col>
            <label>Data inizio</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.dataInizio"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label>Data fine</label>
            <b-form-datepicker
              :locale="'it'"
              v-model="advancedFilter.dataFine"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label>Stato rilevazione</label>
            <b-form-select
              v-model="advancedFilter.stato"
              :options="[
                { value: null, text: 'Seleziona lo stato' },
                { value: 2, text: 'Pubblica' },
                { value: 1, text: 'Interna' },
                { value: 0, text: 'Esculsa' },
              ]"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-4">
          <b-col cols="auto">
            <b-button style="align-self: right">Filtra</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-overlay :show="loading" rounded="sm" class="overlay-placeholder">
      <b-card v-if="dossier">
        <div class="d-flex flex-column justify-content-between mb-2">
          <div>
            <h2>
              <span v-if="dossier.codice">{{ dossier.codice }} -</span>
              {{ dossier.titolo }}
            </h2>
          </div>
          <div>
            <div v-html="dossier.descrizione"></div>
          </div>
        </div>
        <span style="color: gray">{{ dossier.annotazioni }}</span>
        <hr />

        <div
          @click="tmpItem = item"
          v-b-toggle.sidebar-rilevazione
          class="mb-4 axerta-card-rilevazione-reduced"
          v-for="item of computed_items"
          :key="item.id"
          :style="borderStato(item.stato)"
        >
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div
              v-if="item.elementiMultimediali.length > 0"
              class="anteprima-rilevazione"
            >
              <img :src="item.elementiMultimediali[0].urlElemento" />
            </div>
            <div class="flex-grow">
              <div
                v-if="item.descrizioneHistory.length > 0"
                class="d-flex justify-content-between header mb-4"
              >
                <span v-if="item.descrizioneHistory[0].testo">{{
                  item.descrizioneHistory[0].testo
                }}</span>
              </div>

              <div class="d-flex justify-content-end text-muted flex-wrap">
                <span class="mr-4">
                  <b-icon-folder-fill class="mr-2"></b-icon-folder-fill
                  >{{ item.codiceOrdineDiServizio }}
                </span>
                <span class="mr-4">
                  <b-icon-map class="mr-2"></b-icon-map>{{ item.luogoInizio }}
                </span>
                <span class="mr-4">
                  <b-icon-calendar2-week-fill
                    class="mr-2"
                  ></b-icon-calendar2-week-fill
                  ><span>
                    {{ formatDateTime(item.dataOraInizio) }}
                    <b-icon-arrow-right></b-icon-arrow-right>
                    {{ formatDateTime(item.dataOraFine) }}
                  </span>
                </span>
                <span class="mr-4">
                  <b-icon-person-fill class="mr-2"></b-icon-person-fill
                  >{{ item.CieRegistrato }}
                </span>
                <span class="mr-4">
                  <b-icon-camera-fill class="mr-2"></b-icon-camera-fill
                  >{{ item.elementiMultimediali.length }}
                </span>
                <span :style="colorStato(item.stato)">
                  <b-icon-circle-fill></b-icon-circle-fill>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-rilevazione"
      right
      shadow
      lazy
      @hidden="resetData"
      @shown="sidebarRilevazioneVisible = true"
      :visible="sidebarRilevazioneVisible"
    >
      <SingolaRilevazioneDettagliata
        @closeSidebar="closeSidebar"
        :item="tmpItem"
      ></SingolaRilevazioneDettagliata>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService"
import SingolaRilevazioneDettagliata from "@/views/axerta/SingolaRilevazioneDettagliata"

export default {
  name: "ListaRilevazioni",
  components: {
    SingolaRilevazioneDettagliata,
  },
  created() {
    this.idDossier = this.$route.params.idDossier

    this.getRilevazioni()
    this.getDossier()
    this.getCIEOptions()
    this.getOrdineOptions()
  },
  data() {
    return {
      toggleVista: false,
      loading: false,
      advancedFilter: {
        CIE: null,
        ordine: null,
        dataInizio: null,
        dataFine: null,
        stato: null,
      },
      CIEOptions: [{ value: null, text: "Seleziona il Cie" }],
      ordineOptions: [{ value: null, text: "Seleziona l'Ordine di servizio" }],

      sidebarRilevazioneVisible: false,
      advancedFilter_visible: false,
      show: false,
      currentImg: "",
      filter: "",
      idDossier: null,
      currentItem: null,
      tmpItem: {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
        data_suggerita: "",
        ora_suggerita: "",
        sospettati: null,
        annotazioni: "",
      },

      items: [],
      descrizioneHistoryVisible: false,
      nuovaDescrizione: "",
      dossier: null,
    }
  },
  methods: {
    generaDocumentoDiDossier() {
      this.loading = true
      const service = new AxiosService(
        "DossierManager/CreateDossierFile/" + this.idDossier
      )
      service
        .read()
        .then((res) => {
          window.open("https://api-axerta.whappy.it/" + res, "_blank").focus()
        })
        .finally(() => {
          this.loading = false
        })
    },
    colorStato(idStato) {
      if (idStato == 0) return "color:firebrick;"
      if (idStato == 1) return "color:orange;"
      if (idStato == 2) return "color:forestgreen;"
      if (idStato == 3) return "color:purple"
    },
    borderStato(idStato) {
      if (idStato == 0) return "border:2px solid firebrick;"
      if (idStato == 1) return "border:2px solid orange;"
      if (idStato == 2) return "border:2px solid forestgreen;"
      if (idStato == 3) return "border:2px solid purple;"
    },
    getCIEOptions() {
      const service = new AxiosService("User")
      service.read().then((res) => {
        res.forEach((element) => {
          this.CIEOptions.push({
            value: element.id,
            text: element.nome + " " + element.cognome,
          })
        })
      })
    },
    getOrdineOptions() {
      const service = new AxiosService(
        "DossierManager/GetOrdiniDiServizio/" + this.idDossier
      )
      service.read().then((res) => {
        res.forEach((element) => {
          this.ordineOptions.push({
            value: element.id,
            text: element.codice + " - " + element.titolo,
          })
        })
      })
    },
    closeSidebar() {
      this.sidebarRilevazioneVisible = false
      this.getRilevazioni()
    },
    resetData() {
      this.tmpItem = {}
    },
    formatDateTime(string) {
      return (
        new Date(string).toLocaleDateString("it") +
        " " +
        new Date(string).toLocaleTimeString("it", {
          hour: "2-digit",
          minute: "2-digit",
        })
      )
    },
    openOrdineDiServizio() {
      this.$router.push(
        "/axerta/ordine-di-servizio/" + this.$route.params.idDossier
      )
    },
    setCurrentImg(img) {
      this.currentImg = {}
      this.currentImg = img
    },
    getDossier() {
      const service = new AxiosService(
        "DossierManager/GetDossier/" + this.idDossier
      )
      service.read().then((res) => {
        this.dossier = res
      })
    },
    getRilevazioni() {
      this.loading = true
      this.items.splice(0)
      const service = new AxiosService(
        "DossierManager/GetRilevazioniDettaglio/" + this.idDossier + "/0"
      )
      service
        .read()
        .then((res) => {
          res.map((x) => (x.editMode = false))
          this.items = res
          console.log(res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setClassStato(id) {
      let imgClass = ""
      switch (id) {
        case 0:
          imgClass = "Interna"
          break
        case 1:
          imgClass = "Esclusa"
          break
        case 2:
          imgClass = "Pubblicazione"
          break
        case 3:
          imgClass = "Inserita"
          break
      }
      return imgClass
    },
    showDescrizioneHistory() {
      this.descrizioneHistoryVisible = true
    },
    hideDescrizioneHistory() {
      this.descrizioneHistoryVisible = false
    },
    salvaDescrizione(item) {
      const service = new AxiosService(
        "DossierManager/EditDescrizioneRilevazione/" + item.id
      )
      const nuovaDescrizioneObj = {
        descrizione: this.nuovaDescrizione,
        elementiMultimediali: [],
      }

      service
        .create(nuovaDescrizioneObj)
        .then((res) => console.log(res))
        .finally(() => {
          this.descrizioneHistoryVisible = false
          item.editMode = false
        })
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0
    },
    computed_items() {
      if (!this.items) {
        return []
      }

      return this.items.filter((item) => {
        if (
          this.advancedFilter.ordine &&
          item.idOrdineDiServizio != this.advancedFilter.ordine
        )
          return false
        if (
          this.advancedFilter.CIE &&
          item.idCieRegistrato != this.advancedFilter.CIE
        )
          return false
        if (
          this.advancedFilter.stato &&
          item.stato != this.advancedFilter.stato
        )
          return false
        if (
          this.advancedFilter.dataInizio &&
          new Date(item.dataOraInizio) <
            new Date(this.advancedFilter.dataInizio)
        )
          return false
        if (
          this.advancedFilter.dataFine &&
          new Date(item.dataOraFine) > new Date(this.advancedFilter.dataFine)
        )
          return false
        return true
      })
    },
    // ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },
  watch: {
    toggleVista() {
      this.$router.push(
        "/axerta/lista-rilevazioni-dettagliata/" + this.$route.params.idDossier
      )
    },
  },
}
</script>

<style>
.stato:hover {
  cursor: pointer;
}

.images {
  padding: 1rem 0;
}

img {
  border-radius: 5px;
}

.Pubblicazione {
  border: 4px solid green;
}

.Interna {
  border: 4px solid orange;
}

.Esclusa {
  border: 4px solid maroon;
  opacity: 0.5;
}

.Inserita {
  border: 4px solid purple;
}
</style>
